/* eslint-disable @typescript-eslint/no-unused-vars */

export const defaultServicerName: string = 'Intrum';
export const defaultBrokerCode: string = 'INTRUM';

export const defaultReqConnMetricsCESValue: string = 'Request Metrics from CES?';
export const defaultEmailSubject: string = 'Please Review Advisory Note';

export const luYesNo: string = 'No,Yes';
export const luYesNoNA: string = 'Yes,No,Unknown';
export const luVacant: string = 'Yes,No,Unknown';
export const luUnknown: string = 'Unknown';

export const costsCurrency: string = 'EUR';

export const connPickerLabel_ConnID: string = 'Connection ID';
export const connPickerLabel_ConnGroupID: string = 'Connection Group ID';
export const connPickerLabel_AssetID: string = 'Asset ID';

export const connectionStatusOptions: string = 'Not Judicialised,Foreclosure (Pre-Auction),Foreclosure (Post-Auction),Insolvency,REO,Resolved';

export const defaultListAttType: string = 'Supporting'; // options: "Supporting", "CES1"


export const wfStatusSaved: string = 'Saved';
export const wfStatusDraft: string = 'Draft';
export const wfStatusServicer: string = 'Servicer';
export const wfStatusRejected: string = 'Rejected'; // terminal
export const wfStatusWithdrawn: string = 'Withdrawn'; // terminal
export const wfStatusCES1: string = 'CES1';
export const wfStatusCES2: string = 'CES2';
export const wfStatusCECA: string = 'CECA'; // alias for CES2 in CECA workflow
export const wfStatusCGI1: string = 'CGI1';
export const wfStatusCGI2: string = 'CGI2';
export const wfStatusLTH: string = 'EO'; // old: "LTH" for "Legal Title Holder", new: "EO" for "Economic Owner"
export const wfStatusREO1: string = 'REO1';
export const wfStatusREO2: string = 'REO2';
export const wfStatusCompleted: string = 'Completed'; // terminal
export const wfStatusApproved: string = 'Approved'; // terminal


export const wfActionProvideAdditionalInfo: string = 'Provide Additional Information';
export const wfActionReassign: string = 'Reassign';
export const wfActionRecommend: string = 'Recommend';
export const wfActionReject: string = 'Reject';
export const wfActionRequestAdditionalInfo: string = 'Request Additional Information';
export const wfActionSignApprove: string = 'Sign and Approve';
export const wfActionSignConsent: string = 'Sign and Consent';
export const wfActionSignRecommend: string = 'Sign and Recommend';
export const wfActionSubmit: string = 'Submit';
export const wfActionSubmitAdditionalInfo: string = 'Submit Additional Information';
export const wfActionWithdraw: string = 'Withdraw';


export const htmlDefaultPropertyDetailsBgInfo: string = '';
export const htmlDefaultProposalInfo: string = '';
export const htmlDefaultDetailsInfo: string = '';
export const htmlDefaultSupportingTables: string = '';
export const htmlDefaultRecommendation: string = '';
export const htmlDefaultRecCommentsApproval: string = `${defaultServicerName} requests approval for [...]`;
export const htmlDefaultRecCommentsConsent: string = `${defaultServicerName} requests consent for [...]`;
