/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useRef, useMemo, useEffect } from 'react';
import JoditEditor from 'jodit-react';
import * as Consts from "../../Helpers/Consts";
import { NVL } from '../../Helpers/GenUtil2';


export interface IRichTextAreaProps {
  content: string;
  onUpdate: (s: string) => void;
  onUpdate2: (evt: any, s?: string) => void;
  placeholder?: string;
}

export const RichTextArea: React.FunctionComponent<IRichTextAreaProps> = (props: React.PropsWithChildren<IRichTextAreaProps>) => {

  useEffect(() => {
    setContent(props.content);
  }, [props.content]);

  const [content, setContent] = useState(props.content);

  let config = useMemo<any>(() => {
    return {
      // 'disablePlugins': 'about,class-span,clipboard,copyformat,drag-and-drop,drag-and-drop-element,file,image,image-processor,image-properties,focus,iframe,hr,hotkeys,fullsize,preview,print,speech-recognize,spellcheck,symbols,video,xpath,stat,powered-by-jodit,media,search,paste-from-word,paste-storage,sticky,size,add-new-line',
      'disablePlugins': 'about,iframe,xpath,stat,powered-by-jodit,sticky,size,add-new-line',
      "buttons": "bold,italic,underline,strikethrough,eraser,ul,ol,font,fontsize,paragraph,lineHeight,hr,table,link,symbols,indent,outdent,left,brush,spellcheck",
      placeholder: NVL(props.placeholder, 'Enter content here...'),
      // spellcheck: true
    };
  }, [props.placeholder]);


  function onBlur(newContent: any) {
    setContent(newContent);
    props.onUpdate(newContent);
    props.onUpdate2(null, newContent);
  }

  return (
    <>
      <JoditEditor
        value={content}
        config={config}
        onBlur={onBlur}
      />
    </>
  );
};

/*

  JoditEditor notes:


  standard:
  about,backspace,bold,clean-html,color,copy-format,enter,font,format-block,fullsize,iframe,image,image-processor,link,ordered-list,paste,redo-undo,source,table

  basic:
  about,backspace,bold,enter,font,link,redo-undo

  full:
  about,add-new-line,backspace,bold,class-span,clean-html,clipboard,color,copy-format,drag-and-drop,drag-and-drop-element,enter,error-messages,file,focus,font,format-block,fullsize,hotkeys,hr,iframe,image,image-processor,image-properties,indent,inline-popup,justify,key-arrow-outside,limit,line-height,link,media,mobile,ordered-list,paste,paste-from-word,paste-storage,placeholder,powered-by-jodit,preview,print,redo-undo,resize-cells,resize-handler,resizer,search,select,select-cells,size,source,spellcheck,stat,sticky,symbols,tab,table,table-keyboard-navigation,tooltip,video,wrap-nodes,xpath


  "preset": "inline",
  "showCharsCounter": false,
  "showWordsCounter": false,
  "showXPathInStatusbar": false
  "iframe": true,
  "spellcheck": true,


  all plugins available:
  about
  add-new-line
  backspace
  bold
  class-span
  clean-html
  clipboard
  color
  copyformat
  drag-and-drop
  drag-and-drop-element
  enter
  error-messages
  file
  focus
  font
  format-block
  fullsize
  hotkeys
  hr
  iframe
  image
  image-processor
  image-properties
  indent
  inline-popup
  justify
  key-arrow-outside
  limit
  line-height
  link
  media
  mobile
  ordered-list
  paste
  paste-from-word
  paste-storage
  placeholder
  powered-by-jodit
  preview
  print
  redo-undo
  resize-cells
  resize-handler
  resizer
  search
  select
  select-cells
  size
  source
  speech-recognize
  spellcheck
  stat
  sticky
  symbols
  tab
  table
  table-keyboard-navigation
  tooltip
  video
  wrap-nodes
  xpath

*/